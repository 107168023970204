import {Utils} from "../classes/Utils";

export function EditableTable(tableName) {
    this.$this = $("#" + tableName);
    this._this = this;
    this.$tds = this.$this.find('.credit_td');
    this.value_container_class = '.credit_value';
    this.$credit_value = this.value_container_class;
    this.$credit_input = $('.credit_input');
    this.$form = this.$this.parents().find("form");
    this.init();
}

EditableTable.prototype.init = function () {

    this.$tds.on('dblclick', this.onTableDblClick.bind(this));
    $(document).on('click touchstart', this.onDocumentClick.bind(this));

    //таблица скорее всего является частью формы, необходимо запретить отправку по нажатию Enter
    this.$form.on('keydown', function (event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    });
};

EditableTable.prototype.onTableDblClick = function (e) {
    let $clicked_td = $(e.currentTarget),
        $value_div = $clicked_td.find(this.value_container_class);
    this.operateInput($value_div);
};

EditableTable.prototype.onDocumentClick = function (e) {
    let $clicked = $(e.target);
    if ($clicked.is(this.$credit_input)) {
        return false;
    }
    this.closeAllInputs();
};

EditableTable.prototype.inputOpenInFirstTime = function ($input) {
    return $input.data("first-open") === "Y";
};

EditableTable.prototype.hasInput = function ($value_div) {
    return $value_div.parent().find("input").length;
};

EditableTable.prototype.operateInput = function ($value_div) {
    if (!this.hasInput($value_div)) {
        //if we haven't input - append it
        $value_div.after(this.getInput($value_div));
    }
    this.showInput($value_div);
};

EditableTable.prototype.showInput = function ($value_div) {
    $value_div.hide();
    let $input = $value_div.next();
    $input.show()
          .focus()
          .select()
        //повесим на него обработчик нажатий клавиш
          .on("keyup", this.onInputClick.bind(this));
};

/**
 * close (or remove) input and write it's value in content div
 * @param $input
 */
EditableTable.prototype.closeInput = function ($input) {
    let div_class = "",
        $div = $input.prev(),
        div_val = +$div.data("input-value"),
        input_val = +$input.val(),
        formatted_val = Utils.number_format(input_val, 0, "", " "),
        isNull = input_val === 0,
        div_val_str = isNull ? "-" : formatted_val;
    //if input open in first time and was unchanged
    if (this.inputOpenInFirstTime($input) && div_val === input_val) {
        $input.remove();
    } else {
        $input
            .attr("data-first-open", "N")
            .hide();
        div_class = "red";
    }
    $div
        .html(div_val_str)
        .attr("data-input-value", input_val)
        .addClass(div_class)
        .show();
};

EditableTable.prototype.closeAllInputs = function (e) {
    let _this = this;
    $('.credit_input:visible').each(function () {
        _this.closeInput($(this));
    });
};

//close Input excel-like on enter click and select next in string
EditableTable.prototype.onInputClick = function (e) {
    let $current_input = $(e.target);
    switch (e.key) {
        case "Enter":
            e.preventDefault();
            $current_input.unbind("keyup");
            this.closeInput($current_input);
            this.triggerNextTd($current_input);
            break;
        default:
            break;
    }
};

EditableTable.prototype.getInput = function ($clicked) {

    let $inputName = $clicked.data("input-name"),
        $inputValue = $clicked.data("input-value"),
        $inputIndex = $clicked.data("index");

    return $("<input type='text'>")
        .addClass("credit_input")
        .attr("name", $inputName)
        .attr("value", $inputValue)
        .attr("data-first-open", "Y")
        .attr("data-index", $inputIndex);
};

EditableTable.prototype.triggerNextTd = function ($current_input) {
    //find next field
    let $div = $current_input.prev(),
        cur_val = $div.data("index"),
        next_val = cur_val + 1;

    //найдем следующий инпут по индексу (сверху вниз и справа-налево)
    let $nextTD = $('div[data-index="' + next_val + '"]').parent();

    //если не нашли значит конец таблицы
    if ($nextTD.length === 0) {
        return false;
    }
    $nextTD.trigger("dblclick");
};
