import "../Enums";
import {legacy} from "../../legacy";
import {diary} from "../../index";
import {GroupsPayments} from "./GroupsPayments";
import {Pages} from "./Pages";
import {EditableTable} from "../../modules/EditableTable";


export class GroupsCredit extends Pages{

    initPageHandlers() {
        super.initPageHandlers();

        let table = new EditableTable("credit_table");
        table.init();

    }

}
